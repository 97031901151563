import React, {useEffect, useState} from 'react';
import {graphql} from 'gatsby';
import {Link, Trans, useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';
import InnerPage from "../components/innerPage";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { JSEncrypt }  from "jsencrypt";
import axios from 'axios';

/**
 * Enquiry Member Points file of the website
 * @returns {JSX.Element}
 * @private
 */
const EnquiryMemberPoints = () => {
    const pageModeFlag = {
        FORM: "FORM",
        RESULT: "RESULT",
    }

    const {t} = useTranslation();
    const {languages, originalPath} = useI18next();
    const [errHeadline, setErrHeadline] = useState("");
    const [pageMode, setPageMode] = useState(pageModeFlag.FORM);
    const [resultData, setResultData] = useState(undefined);

    const breadcrumbs = [
        {
            title: t('enquiryMemberPoints'),
        }
    ]

    const mbrInfoSma = Yup.object().shape({
       mid: Yup.number()
           .positive().min(88000000, t('pleaseInputValidMemberId'))
           .max(88999999, t('pleaseInputValidMemberId'))
           .required(t('pleaseInputValidMemberId')),
        hkid: Yup.string().required(t('pleaseInputValidHkid')).length(5, t('pleaseInputValidHkid'))

    });

    const ResultTextBox = function(props) {
        const {
            id,
            value,
            label
        } = props

        return (
            <div className="form-floating mb-3">
                <input type="text" className="form-control bg-white border-0 " id={id}
                       placeholder={id} disabled={true}
                       value={value}/>
                <label htmlFor={id}>{label}</label>
            </div>
        )
    }

    const b64PublicKey = process.env.GATSBY_RSA_PUBLIC_KEY_B64;
    const publicKey = atob(b64PublicKey);
    const encrypt = new JSEncrypt();
    encrypt.setPublicKey(publicKey)

    const onBackBtnClick = () => {
        setPageMode(pageModeFlag.FORM)
    }

    useEffect(() => {

    }, [])

    return (
        <Layout>
            <SEO title={t('enquiryMemberPoints')}/>
            {/*Start you coding...*/}
            <InnerPage
                breadcrumbs={breadcrumbs}
                pageTitle={t('enquiryMemberPoints')}
            >
                <div className="row">
                    {pageMode === pageModeFlag.FORM && (
                        <>
                            <div className="col-12 col-md-6 mx-auto">
                                { typeof errHeadline !== "undefined" && errHeadline !== "" && (
                                    <>
                                        <div className="alert alert-danger" role="alert">
                                            <i className="bi bi-exclamation-triangle"></i> {errHeadline}
                                        </div>
                                    </>
                                )}
                                {/*<div className="alert alert-danger col-12 col-md-6" role="alert">*/}
                                {/*    <i className="bi bi-exclamation-triangle"></i> {t('noRecordPlsCheck')}*/}
                                {/*</div>*/}
                                <Formik
                                    initialValues={{ mid: "", hkid: "" }}
                                    validationSchema={mbrInfoSma}
                                    onSubmit={(values, {setSubmitting}) => {
                                        values["hkid"] = values["hkid"].toUpperCase();
                                        let encryptedData = encrypt.encrypt(JSON.stringify(values));

                                        axios.post(process.env.GATSBY_MS_API_URL + '/public/get-member-info', {
                                            data: encryptedData,
                                        })
                                            .then(function (res) {
                                                setErrHeadline("");
                                                setResultData(res.data);
                                                setPageMode(pageModeFlag.RESULT)
                                                setSubmitting(false);
                                            })
                                            .catch(function(err) {
                                                if (err.response.status === 400) {
                                                    setErrHeadline(t('noRecordPlsCheck'))
                                                }
                                                setPageMode(pageModeFlag.FORM);
                                                setResultData(undefined);
                                                setSubmitting(false);
                                            })
                                    }}
                                    onReset={() => {
                                        setErrHeadline("")
                                    }}
                                >
                                    {({ touched,
                                          errors,
                                          isSubmitting,
                                          resetForm,
                                      }) => (
                                        <Form>
                                            <div className="form-floating mb-3">
                                                <Field
                                                    type="number"
                                                    name="mid"
                                                    placeholder={t('memberId')}
                                                    id="mid"
                                                    className={`form-control ${
                                                        touched.mid && errors.mid ? "is-invalid" : ""
                                                    }`}
                                                />
                                                <label htmlFor="mid">{t('memberId')}</label>
                                                <ErrorMessage
                                                    component="div"
                                                    name="mid"
                                                    className="invalid-feedback"
                                                />
                                            </div>

                                            <div className="form-floating mb-3">
                                                <Field
                                                    type="password"
                                                    name="hkid"
                                                    placeholder={t('hkidFirst5char')}
                                                    id="hkid"
                                                    className={`form-control text-transform-uppercase ${
                                                        touched.hkid && errors.hkid ? "is-invalid" : ""
                                                    }`}
                                                />
                                                <label htmlFor="hkid">{t('hkidFirst5char')}</label>
                                                <ErrorMessage
                                                    component="div"
                                                    name="hkid"
                                                    className="invalid-feedback"
                                                />
                                            </div>

                                            <div className={"text-end"}>
                                                <button
                                                    type="reset"
                                                    className="btn btn-outline-secondary btn-block mx-2"
                                                    onClick={resetForm}
                                                >
                                                    {t('reset')}
                                                </button>

                                                <button
                                                    type="submit"
                                                    className="btn btn-danger btn-block theme-color-bg-mandatory"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ? t('pleaseWait') : t('submit')}
                                                </button>
                                            </div>

                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </>
                    )}

                    {pageMode === pageModeFlag.RESULT && typeof resultData !== "undefined" && (
                        <>
                            <div className="col-12 col-md-6 mx-auto">
                                <p onClick={onBackBtnClick} className={"hover-highlight"}>
                                    {"<< " + t('back')}
                                </p>
                                <h3 className={"mb-4"}>
                                    {t('memberRecord')}
                                </h3>

                                <ResultTextBox
                                    id={"midResult"}
                                    value={resultData["member_info"]["mid"]}
                                    label={t('memberId')}
                                />

                                <ResultTextBox
                                    id={"mNameResult"}
                                    value={`${resultData["member_info"]["m_chinese_name"]}  ${resultData["member_info"]["m_eng_name"]}`}
                                    label={t('name')}
                                />

                                <ResultTextBox
                                    id={"mMemberPoints"}
                                    value={resultData["loyalty_point"]}
                                    label={t('memberPoints')}
                                />

                                <ResultTextBox
                                    id={"mExpDateResult"}
                                    value={resultData["member_info"]["m_expiry_date"]}
                                    label={t('expiryDate')}
                                    />

                                <h4 className={"my-4"}>
                                    {t('masterCardInfo')}
                                </h4>

                                <ResultTextBox
                                    id={"masterMidResult"}
                                    value={resultData["master_card"]["mid"]}
                                    label={t('memberId')}
                                />

                                <ResultTextBox
                                    id={"masterMNameResult"}
                                    value={`${resultData["master_card"]["m_chinese_name"]}  ${resultData["master_card"]["m_eng_name"]}`}
                                    label={t('name')}
                                />

                                <ResultTextBox
                                    id={"masterMExpDateResult"}
                                    value={resultData["master_card"]["m_expiry_date"]}
                                    label={t('expiryDate')}
                                />

                                {/*//TODO: do trans_history fields*/}

                                <div className={"text-end"}>
                                    <button
                                        type="button"
                                        className="btn btn-danger btn-block theme-color-bg-mandatory"
                                        onClick={onBackBtnClick}
                                    >
                                        {t('back')}
                                    </button>
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </InnerPage>
        </Layout>
    );
};

export default EnquiryMemberPoints;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;